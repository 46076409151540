@import "../../config/colors.scss";

.topbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   box-shadow: 0 0 5px 0 rgba($color: #000, $alpha: 0.1);
   height: 50px;
   padding: 0 2rem;
   margin-bottom: 1rem;

   .form {
      display: flex;
      align-items: center;

      input {
         padding: 0.5rem 1rem;
         border-radius: 5px;
         border: 1px solid #ccc;
         box-shadow: inset 0 0 3px #ccc;
         width: 100%;

         &:active,
         &:hover {
            outline: 2px solid rgba($cl-sidebar-grad-end, 0.6);
         }
      }

      .error {
         color: red;
      }
   }
   button {
      background: transparent;
      border: none;

      .icon {
         font-size: 30px;

         &:hover {
            color: rgba($cl-sidebar-grad-end, 0.6);
         }
      }
   }
   .user {
      position: relative;
      cursor: pointer;

      .userMenu {
         background: $cl-text-white;
         width: fit-content;
         position: absolute;
         top: 45px;
         right: 20px;

         border-radius: 5px;
         box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
         display: none;
         height: 0;
         opacity: 0;
         transition: all 0.8s;
         z-index: 99;

         div {
            width: 120px;
         }
      }
      .active {
         display: flex;
         flex-direction: column;
         height: fit-content;
         padding: 1rem 1rem;
         opacity: 1;
         animation: menu 0.5s;
      }
      @keyframes menu {
         0% {
            height: 0;
            opacity: 0;
         }
         50% {
            height: fit-content;
         }
         100% {
            height: fit-content;
            opacity: 1;
         }
      }
   }
}
