@import "../../config/colors.scss";
.sidebar {
   background: $cl-sidebar-bg;
   background: linear-gradient(
      180deg,
      $cl-sidebar-grad-start 0%,
      $cl-sidebar-grad-end 80%
   );
   height: 100%;
   width: 70px;
   padding: 2rem 1.5rem 0;
   transition: width 0.3s;
   position: fixed;
   z-index: 99;

   .sidebar-brand {
      display: flex;
      width: 100%;

      justify-content: center;
      align-items: center;

      margin-bottom: 3rem;

      img {
         width: 100%;

         object-fit: cover;
         @media screen and (max-width: 768px) {
            display: none;
         }
      }
   }

   nav {
      display: flex;
      flex-direction: column;
      width: 100%;

      .navItem {
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5rem;
         color: $cl-text-white;

         span {
            display: none;
         }

         &:hover,
         &:active {
            color: $cl-link-hover;
         }

         @media screen and (min-width: 768px) {
            justify-content: flex-start;

            span {
               display: inline;
            }
         }
      }

      a {
         color: inherit;
         text-decoration: none;
      }
   }
   @media screen and (min-width: 768px) {
      width: 170px;
      align-items: flex-start;
   }
}

.icon {
   font-size: 22px;

   @media screen and (min-width: 768px) {
      margin-right: 1rem;
   }
}
